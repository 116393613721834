/* 
Text below will be shown on the contact footer.
Use backticks to write a string on multiple lines.
*/

import { ContactText } from '../../../types';

export const contactText: ContactText = {
  // the address is an array of strings, with each array element being one line of the address
  labAddress: [
    'Room 3-137',
    'Massachusetts Institute of Technology',
    '77 Massachusetts Avenue',
    'Cambridge, MA 02139-4307',
  ],
  labAddressLink: 'https://goo.gl/maps/fCbetriEVsQtwqgF8',
  youtubeChannel: 'https://www.youtube.com/user/MITbiomimetics/videos?view=0',
  accessibility: 'https://accessibility.mit.edu/',
};
