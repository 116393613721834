import React, { useEffect, useRef } from 'react';

interface Props {
  children: React.ReactNode;
}

const FadeInSection = (props: Props) => {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.unobserve(domRef.current as Element);
      }
    });
    const refCurrent = domRef.current;
    observer.observe(refCurrent as Element);
    return () => observer.unobserve(refCurrent as Element);
  }, []); // [] means useEffect is only called when the component mounts
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
};

export default FadeInSection;
