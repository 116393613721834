import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';

import AnimatedButtonInternal from '../AnimatedButtonInternal';
import Divider from '../Divider';
import FadeInSection from '../FadeInSection';
import { colorPalette, screenWidthSettings } from '../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    coverContainer: {
      color: 'black',
      height: 'auto',
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5rem 0',
    },
    title: {
      fontFamily: "'Titillium Web', 'Helvetica', 'Arial', sans-serif",
    },
    aboutLabText: {
      width: '100%',
      maxWidth: screenWidthSettings.laptopScreenSize,
      margin: '2rem 15px',
      justifyContent: 'center',
    },
    textSection: {
      marginBottom: '1rem',
    },
    bulletPoints: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

const About = () => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.mobileScreenSize.toString()}px)`
  );
  const isTabletScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.tabletScreenSize.toString()}px)`
  );
  return (
    <section className={classes.coverContainer}>
      <Typography
        className={classes.title}
        align='center'
        color='inherit'
        variant={isMobileScreen ? 'h4' : isTabletScreen ? 'h3' : 'h2'}
        component='h1'
      >
        Interested in working with us?
      </Typography>
      <Divider color={colorPalette.lightBlue} />
      <FadeInSection>
        <Typography
          className={classes.aboutLabText}
        >
          <Typography
            align='center'
            color='inherit'
            variant={isMobileScreen ? 'body2' : 'body1'}
            component='p'
          >
            <p className={classes.textSection}>
              The Biomimetic Robotics Laboratory at MIT focuses on designing
              and controlling robots using insights taken from the natural world.
              Many animals have fun and can display incredible feats of speed and agility
              that are sources of inspiration for the robots designed by the group.
            </p>
            <p className={classes.textSection}>
              We're always open to new members who will fit in well with the group!
	      We value an open and collaborative atmosphere with no hierarchy, and a hands-on attitude.
	      Our group members come from all engineering fields, are passionate about
              their work, highly self-motivated, and are able to direct their own curiosity/research.
              We particularly encourage applications from students with underrepresented backgrounds.
	      If you're interested in working with us, please email 'apply2biomimetics -at- mit.edu' with
            </p>
          </Typography>
          <Typography
            className={classes.bulletPoints}
            align='left'
            color='inherit'
            variant={isMobileScreen ? 'body2' : 'body1'}
            component='p'
          >
            <ul>
              <li>a brief description of what you're interested in working on and why.</li>
              <li>attach your CV and transcript.</li>
	      <li>the email subject should indicate the position level (undergrad, grad, postgrad).</li>
	      <li>for undergrad and grad level positions, you must be currently enrolled or admitted to MIT.</li>
            </ul>
          </Typography>
        </Typography>
      </FadeInSection>
      <AnimatedButtonInternal
        buttonText='Learn More'
        buttonTextColor='white'
        buttonTextHoverColor='white'
        buttonColor={colorPalette.lightBlue}
        buttonHoverColor='rgba(0,0,0,0.2)'
        link={`/research`}
      />
    </section>
  );
};

export default About;
