import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import NewsCarousel from './NewsCarousel';
import About from './About';
import Contact from './Contact';
import { colorPalette } from '../../constants';
import ScrollUpButton from '../ScrollUpButton';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <NewsCarousel />
      <About />
      <Contact />
      <ScrollUpButton arrowColor={colorPalette.lightBlue} />
    </div>
  );
};

export default Home;
