import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '4px',
      width: '100px',
      margin: '1rem auto 1rem auto',
      position: 'relative',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    dot: {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '5px',
      borderRadius: '5px',
      background: 'white',
      animationDuration: '2s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationName: '$movebg',
    },
    '@keyframes movebg': {
      '0%': {
        left: '0px',
      },
      '100%': {
        left: '105px',
      },
    },
  })
);

const Divider = ({ color }: { color: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ background: color }}>
      <div className={classes.dot}></div>
    </div>
  );
};

export default Divider;
