import React from 'react';
import { Container, Link, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PlaceIcon from '@material-ui/icons/Place';
import PhoneIcon from '@material-ui/icons/Phone';
import YouTubeIcon from '@material-ui/icons/YouTube';

import { contactText } from '../../assets/home/contact/contactText';
import background_image from '../../assets/home/contact/background_image.jpg';
import { screenWidthSettings } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: screenWidthSettings.maxScreenWidth,
      backgroundImage: `url(${background_image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
    },
    textContainer: {
      color: 'white',
      background:
        'linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.6) 100%)',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
    },
    subContainer: {
      fontStyle: 'normal',
      marginTop: '1rem',
      width: '70%',
      maxWidth: '1000px',
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    halfContainer: {
      width: '50%',
      marginTop: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        alignItems: 'flex-start',
        width: '100%',
      },
    },
    halfSubContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    iconAndTextContainer: {
      marginBottom: '10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    addressContainer: {
      alignItems: 'flex-start',
    },
    icon: {
      margin: '0 0.5rem 0 0.5rem',
      color: 'inherit',
      transition: 'color .2s ease-out',
    },
    placeIcon: {
      '&:hover, &:focus': {
        color: '#398CF6',
      },
    },
    phoneIcon: {
      '&:hover, &:focus': {
        color: '#0FA460',
      },
    },
    youtubeIcon: {
      '&:hover, &:focus': {
        color: 'red',
      },
    },
    divider: {
      height: '2px',
      width: '60%',
      minWidth: '250px',
      // maxWidth: '600px',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'white',
      margin: '1.5rem 0rem',
    },
    accessibility: {
      paddingBottom: '2px',
      '&:hover': {
        borderBottom: '1px solid white',
      },
    },
  })
);

const Contact = () => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.mobileScreenSize.toString()}px)`
  );
  const isTabletScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.tabletScreenSize.toString()}px)`
  );
  return (
    <footer className={classes.container}>
      <Container maxWidth={false} className={classes.textContainer}>
        <Typography
          align='center'
          variant={isMobileScreen ? 'h4' : isTabletScreen ? 'h3' : 'h2'}
          component='h1'
          style={{
            fontFamily: "'Titillium Web', 'Helvetica', 'Arial', sans-serif",
          }}
        >
          Contact
        </Typography>
        <address className={classes.subContainer}>
          <div className={classes.halfContainer}>
            <div className={classes.halfSubContainer}>
              <div
                className={[
                  classes.iconAndTextContainer,
                  classes.addressContainer,
                ].join(' ')}
              >
                <a
                  className={[classes.placeIcon, classes.icon].join(' ')}
                  href={contactText.labAddressLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <PlaceIcon fontSize={isMobileScreen ? 'default' : 'large'} />
                </a>
                <div>
                  {contactText.labAddress.map((addressLine) => {
                    return (
                      <Typography key={addressLine} component='p'>
                        {addressLine}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={classes.halfContainer}>
            <div className={classes.halfSubContainer}>
              <div className={classes.iconAndTextContainer}>
                {/* <a
                  className={[classes.phoneIcon, classes.icon].join(' ')}
                  href={'tel:'.concat(contactText.phoneNumber)}
                >
                  <PhoneIcon fontSize={isMobileScreen ? 'default' : 'large'} />
                </a>
                <Typography component='p'>{contactText.phoneNumber}</Typography> */}
              </div>
              <div className={classes.iconAndTextContainer}>
                <a
                  className={[classes.youtubeIcon, classes.icon].join(' ')}
                  href={contactText.youtubeChannel}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <YouTubeIcon
                    fontSize={isMobileScreen ? 'default' : 'large'}
                  />
                </a>
                <Typography component='p'>Youtube</Typography>
              </div>
            </div>
          </div>
        </address>
        <hr className={classes.divider} />
        <Typography component='p'>
          <Link
            className={classes.accessibility}
            href={contactText.accessibility}
            target='_blank'
            color='inherit'
          >
            Accessibility
          </Link>
        </Typography>
      </Container>
    </footer>
  );
};

export default Contact;
