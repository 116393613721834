import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Home from '../components/home/Home';

const HomePage = () => (
  <Layout>
    <SEO
      title='MIT Biomimetic Robotics Lab'
      description='Home page for the MIT Biomimetic Robotics Lab'
    />
    <Home />
  </Layout>
);

export default HomePage;
